:root {
  --radius: 5px;

  --ratio: 1.61803398875;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1.5rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

p {
  line-height: 1.5;
}

h1 {
  line-height: 1.5;
}

h2 {
  line-height: 1.5;
}

.title {
  max-width: 50ch;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.relative {
  position: relative;
}

b {
  font-weight: 700;
}

.section-cta-btn {
  display: block;
  margin: 0 auto;
  line-height: 1.5;
  max-width: max-content;
  text-align: center;
  padding: 20px;
  font-size: 26px;
  background-color: var(--color-blue-cta);
  border-radius: var(--radius);
  cursor: pointer;
  color: white;
}

.section {
  padding-top: var(--s3);
  padding-bottom: var(--s3);
  padding-left: var(--s3);
  padding-right: var(--s3);
}

.section-title {
  font-size: 32px;
  font-weight: 700;

  text-align: center;
  letter-spacing: 3px;
}

.appear-on-tablet {
  display: none;
}

@media (max-width: 768px) {
  
  .section {
    padding-left: var(--s1);
    padding-right: var(--s1);
  }

  .section-cta-btn {
    font-size: 20px;
  }

  .disappear-on-tablet {
      display: none !important;
  }

  .appear-on-tablet {
      display: inherit !important;
  }

}

@media (max-width: 480px) {
  
  .section {
    padding-left: var(--s0);
    padding-right: var(--s0);
  }

  .section-title {
    font-size: 26px;
  }

  .section-cta-btn {
    font-size: 20px;
    padding: 16px;
  }

}
