:root {
  --color_primary: #006494;
  --color_primary_darker: #13293d;
  --color_primary_lighter: #e8f1f2;

  --color_neutral_darkest: black;
  --color_neutral_medium: #a8a8a8;
  --color_neutral_lighter: #e1e1e1;
  --color_neutral_lightest: white;

  --color_bg_header: var(--color_neutral_darkest);
  --color_bg_footer: linear-gradient(165deg, var(--color_primary) 40%, var(--color_primary_darker) 100%);
  --color_bg_main: var(--color_primary_lighter);
  --color_bg_button: var(--color_primary_lighter);
  --color_bg_banner: linear-gradient(black 0%, var(--color_primary_darker) 60%, var(--color_primary) 100%);
  --color_bg_bannerBottom: linear-gradient(var(--color_primary) 0%, var(--color_primary_lighter) 100%);
  /*--color_bg_banner: radial-gradient(50% 75%, var(--color_primary), var(--color_primary_darker));*/
  /*--color_bg_banner: linear-gradient(var(--color_primary_darker) 40%, var(--color_primary) 75%, var(--color_primary_darker) 100%);*/
  /*--color_bg_banner: linear-gradient(var(--color_primary_darker) 60%, var(--color_primary) 100%);*/
  --color_bg_h2: linear-gradient(to right, transparent, var(--color_neutral_lightest) 10%, var(--color_neutral_lightest) 90%, transparent);
  --color_bg_section: linear-gradient(var(--color_primary) 50%, var(--color_primary_darker));

  --color_text_overBgHeader: var(--color_neutral_lighter);
  --color_text_overBgMain: var(--color_primary_darker);
  --color_text_overBgFooter: var(--color_neutral_lighter);
  --color_text_overBgButton: var(--color_primary_darker);
  --color_text_overBgBanner: var(--color_neutral_lightest);
  --color_text_overBgBanner2: var(--color_neutral_lighter);
  --color_text_overH2: var(--color_primary);
  --color_text_overBgSection: var(--color_neutral_lightest);

  --color-blue-cta: #3CA3D5;
  --color-blue: #0F4C75;
  --color-blue-light: #F2FAFF;
  --color-blue-dark: #1B262C;
}

#root {
  color: var(--color_text_overBgMain);
}