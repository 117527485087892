/* Inpired by 3rd example of section "2) CSS hover animations for buttons" in article
 *   https://alvarotrigo.com/blog/10-cool-css-animations-to-add-to-your-website/
 */

.glow-on-hover {
  position: relative;
  z-index: 0;
}

.glow-on-hover:before {
  content: '';

  background: linear-gradient(
    45deg,
    var(--color_primary),
    var(--color_primary_darker),
    var(--color_neutral_medium),
    var(--color_neutral_lighter),
    var(--color_neutral_medium),
    var(--color_primary)
  );
  background-size: 400%;
  filter: blur(5px);

  position: absolute;
  top: -2px;
  left: -2px;
  border-radius: 10px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;

  animation: glowing 20s linear infinite;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:hover:after {
  content: '';

  background: var(--color_bg_button);
  border-radius: 10px;
  height: 100%;
  width: 100%;

  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
