@font-face {
  font-family: 'Lucida Grande';
  src: url('../assets/fonts/Lucida Grande.woff2') format('woff2'),
    url('../assets/fonts/Lucida Grande.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida Grande';
  src: url('../assets/fonts/Lucida Grande Bold.woff2') format('woff2'),
    url('../assets/fonts/Lucida Grande Bold.woff') format('woff');
  font-weight: bold;
  font-display: swap;
}
