* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: white;
}

#root {
  font-family: 'Lucida Grande', Arial, sans-serif;

  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}
