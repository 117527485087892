.image-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background: rgb(17, 17, 17);
  display: block;
}
